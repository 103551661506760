// KBmodal

@import "KBmodal_variables";

@keyframes KBmodalAnimateBackground {
	0%   {background-color: rgba(0, 0, 0, 0.0);}
    100% {background-color: rgba(0, 0, 0, 0.7);}
}
@keyframes KBmodalAnimateContentShow {
	0%   {opacity:0;}
    100% {opacity:0.99;}
}
.KBmodal{
	cursor: pointer; //safari click event fix
}
.KBmodal__opened{
	position: fixed;
	z-index: 9999999999999999999;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; //safari click event fix
    // animation
    animation-name: KBmodalAnimateBackground;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}
.KBmodal__yt{
	width: calc(100% - 75px);
	height: calc(100% - 30px);
	position: relative;
	display: flex;
	align-items: center;
    justify-content: center;
	img{
		display: block;
	}
	iframe{
		max-width: 100%;
		max-height: 100%;
		opacity: 0.99;
	}
}
.KBmodal__image{
	@extend .KBmodal__yt;
	opacity: 0;
    // animation
    animation-name: KBmodalAnimateContentShow;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}
.KBmodal__html{
	opacity: 0;
    // animation
    animation-name: KBmodalAnimateContentShow;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
	cursor: auto;
}
.KBgalleryNav{
	font-size: 50px;
	font-weight: bold;
	background-color: $color__KBmodalNav--bg;
	color: $color__KBmodalNav--text;
	transition: .5s;
	padding: 0px 10px;
	&:hover{
		color: $color__KBmodalNav--textHover;
		background-color: $color__KBmodalNav--bgHover;
	}
}